import AOS from 'aos';


export default class Blog {
    constructor() {

        this.init();
    }


    init() {
        AOS.init();
    }
}