
export default class MaraphonPage {

    constructor() {
        this.headersProgram = document.querySelectorAll('.js-open-close-program');
        this.diagramWraps = document.querySelectorAll('.js-diagram-wrap');
        this.init();
    }

    addListenerShowHide() {
        this.headersProgram.forEach(function (header) {
            header.addEventListener('click', function (e) {
                const programWrap = this.closest('.js-program-wrap');
                const detailBlock = programWrap.querySelector('.detail-block-wrap');
                const showHideText = programWrap.querySelector('.js-show-hide-text');
                const isActiveClassContains = detailBlock.classList.contains('active');
                detailBlock.classList[isActiveClassContains ? 'remove' : 'add']('active');
                programWrap.classList[isActiveClassContains ? 'remove' : 'add']('open');
                showHideText.innerText = !isActiveClassContains ? 'Сховати' : 'Показати';
            })
        })
    }

    drawDiagrams() {
        const _this = this;
        document.addEventListener("DOMContentLoaded", function () {
                if ("IntersectionObserver" in window) {
                    let lazyObjectsObserver = new IntersectionObserver(function (entries, observer) {
                        entries.forEach(function (entry) {
                            if (entry.isIntersecting) {
                                let lazyObject = entry.target;
                                lazyObject.querySelectorAll('.js-diagram-item').forEach(function (diagram) {
                                    setTimeout(function () {
                                        const width = diagram.dataset['width'];
                                        diagram.style.width = `${width}%`;
                                    }, 100)
                                })

                            }
                        });
                    });

                    _this.diagramWraps.forEach(function (diagram) {
                        lazyObjectsObserver.observe(diagram);
                    });
                }
            }
        )
    }

    init() {
        this.addListenerShowHide();
        this.drawDiagrams();
    }
}