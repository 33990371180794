import Masonry from "masonry-layout";

export default class ReviewsPage {
    constructor() {
        this.reviewWrap = document.getElementById('review_all_wrap');
        this.classNameItem = 'js-item-review';
        this.init();
    }


    init() {
        const _this = this;
        var masonry = new Masonry(_this.reviewWrap, {
            itemSelector: `.${_this.classNameItem}`,
            gutter: 0, // Set the gutter size as needed
        });
    }
}