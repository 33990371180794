export default class Video {
    constructor() {
        this.videoBtns = document.querySelectorAll('.js-load-video');
        this.init();
    }


    preload(event) {
        event.preventDefault();
        const btn = event.target;
        const videoWrap = event.target.closest('.js-video-wrap');
        const videoTag = videoWrap.querySelector('video');
        const src = videoTag.getAttribute('data-src');
        videoTag.setAttribute('src', src);
        videoTag.setAttribute('controls', true);
        videoTag.setAttribute('playsinline', true);
        videoTag.play();
        btn.style.display = 'none';
        if (btn.closest('.play-video')) {
            btn.closest('.play-video').style.display = 'none';
        }
    }

    init() {
        const this_ = this;
        this.videoBtns.forEach(function(videoBtn, index){
            videoBtn.addEventListener('click', function(e){
                this_.preload(e);
            })
        })

    }
}