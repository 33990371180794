import Masonry from 'masonry-layout'
import Flickity from 'flickity'

export default class Reviews {
    constructor() {

        this.reviewWrap = document.getElementById('review_wrap');
        if (this.reviewWrap) {
           this.apiUrl = this.reviewWrap.getAttribute('data-url');
        }

        this.reviewsData = [];
        this.videoReviewWrap = document.querySelector('.video-wrap');

        this.init();

    }

    render() {
        if (!this.reviewWrap) {
            return
        }
        const _this = this;
        const classNameItem = 'text-review-item';
        // this.reviewsData.forEach(text_review => {
        //     const reviewDiv = document.createElement('div');
        //     reviewDiv.className = classNameItem;
        //     reviewDiv.innerHTML = `
        //         <div class="text-reviews-author">
        //             <div class="avatar">
        //                 ${text_review.avatar ? `<img class="img" src="${text_review.avatar}">` : `<span class="default-avatar"> ${text_review.author[0]} </span>`}
        //             </div>
        //             <div class="name"> ${text_review.author}  </div>
        //         </div>
        //         <p class="text-reviews-text">${text_review.text}</p>`;
        //     _this.reviewWrap.appendChild(reviewDiv);
        // });
        var masonry = new Masonry(_this.reviewWrap, {
            itemSelector: `.${classNameItem}`,
            gutter: 0, // Set the gutter size as needed
        });
    }

    videoReviews() {
        let groupCells = 1;
        let autoPlay = false;
        let wrapAround = false;
        let contain = true;
        let oneSlideWidth = 286;
        let slides = this.videoReviewWrap.querySelectorAll('.video-item').length - 1;
        let sliderWidth = oneSlideWidth * slides;
        
        if(screen.width <= 1024 && screen.width > 500) {
			groupCells = 2;
		}

        if (sliderWidth > window.innerWidth) {
            autoPlay = 2000;
            wrapAround = true;
            contain = false;
        }
        
        const _this = this;
        var flkty = new Flickity(this.videoReviewWrap, {
            cellAlign: 'center',
            freeScroll: false,
            groupCells: groupCells,
            draggable: true,
            contain: contain,
            wrapAround: wrapAround,
            prevNextButtons: false,
            pageDots: false,
            dragThreshold: 10,
            autoPlay: autoPlay,
            pauseAutoPlayOnHover: true,
            on: {
                ready: function () {
                    _this.videoReviewWrap.addEventListener('settle.flickity', function () {
                        _this.videoReviewWrap.flickity('resize');
                    });
                }
            }
        });
    }

    init() {

        const _this = this;
        // window.addEventListener('load', function () {
        //     fetch(_this.apiUrl, {
        //         'headers': {
        //             'X-Requested-With': 'XMLHttpRequest',
        //             'Content-Type': 'application/json'
        //         }
        //     })
        //         .then(function (res) {
        //             return res.json();
        //         }).then(function (data) {
        //         if (data.status) {
        //             _this.reviewsData = data.data;
        //             _this.render();
        //         }
        //     })
        //
        // })
        this.render();
        this.videoReviews();
    }
}