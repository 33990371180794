export default class Faq {
    constructor() {
        this.questionsTitle = document.querySelectorAll('.js-question');
        this.init();
    }

    init() {
        this.questionsTitle.forEach(function (questionTitle) {
            questionTitle.addEventListener('click', function () {
                this.classList.toggle('is-open');
                const content = this.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + 16 * 2 + "px";
                }
            })
        })
    }
}