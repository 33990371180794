export default class LazyLoadImg {
    constructor() {
        this.lazyImages = [].slice.call(document.querySelectorAll(".lazy"));
    }

    init() {
        const _this = this;
        document.addEventListener("DOMContentLoaded", function () {
                if ("IntersectionObserver" in window) {
                    let lazyObjectsObserver = new IntersectionObserver(function (entries, observer) {
                        entries.forEach(function (entry) {
                            if (entry.isIntersecting) {
                                const tagName = entry.target.tagName.toLowerCase();
                                let imageAttr = 'src';
                                if (tagName === 'video') {
                                    imageAttr = 'poster'
                                }
                                let lazyObject = entry.target;
                                lazyObject[imageAttr] = lazyObject.dataset[imageAttr];
                                lazyObject.classList.remove("lazy");
                                lazyObjectsObserver.unobserve(lazyObject);
                            }
                        });
                    });

                    _this.lazyImages.forEach(function (lazyImage) {
                        lazyObjectsObserver.observe(lazyImage);
                    });
                } else {

                }
            }
        )
    }
}