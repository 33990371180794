import intlTelInput from "intl-tel-input";

export default class phoneNumberInForm {
    constructor(form) {
        this.phoneInput = form.querySelectorAll('input[type="tel"]');
        this.phonesScope = {};
        this.errorInputClassName = 'error';
        this.init();
    }


    addMaskToPhone() {
        const _this = this;
        _this.phoneInput.forEach(function (phone_number) {
            const inputId = phone_number.getAttribute('id');
            _this.phonesScope[inputId] = intlTelInput(phone_number, {
                initialCountry: "auto",
                separateDialCode: true,
                showSelectedDialCode: true,
                utilsScript: "//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.3/js/utils.js",
                // onlyCountries: ["sk", "de", 'ua', "ch", "nl", "at", "dk", 'pl', 'lt', 'lu'],
                geoIpLookup: function (callback) {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            const latitude = position.coords.latitude;
                            const longitude = position.coords.longitude;
                            let countryCode = null;
                            // Call API to get country code based on latitude and longitude
                            fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
                                .then(response => response.json())
                                .then(data => {
                                    countryCode = data.countryCode;
                                    callback(countryCode);
                                    console.log('Country Code:', countryCode);

                                    // Use the country code as needed
                                })
                                .catch(error => console.error('Error:', error));
                            return countryCode;
                        });

                    } else {
                        console.error('Geolocation is not supported by this browser.');
                    }
                    // const countryCode = _this.getCountryCode();
                    // console.log(countryCode , 'countryCode');
                    // callback('sk');
                    //
                    // fetch("https://ipapi.co/json")
                    //     .then(function (res) {
                    //         return res.json();
                    //     })
                    //     .then(function (data) {
                    //         callback(data.country_code);
                    //     })
                    //     .catch(function () {
                    //         callback("uk");
                    //     });
                },
            });

            phone_number.addEventListener('input', function (e) {
                console.log('!!!!!!!')
                const isValidNumber = _this.phonesScope[inputId].isValidNumber();
                const newValue = this.value.trim().replace(/[^\d.-]+/g, '');
                this.value = newValue;
                phone_number.classList[isValidNumber ? 'remove' : 'add'](_this.errorInputClassName);
            })
        })

    }


    init() {
        //this.addMaskToPhone();
    }

}