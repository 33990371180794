import AOS from 'aos';

export default class ArticlePage {
    constructor() {
        this.init();
    }


    init() {
        AOS.init();
    }
}