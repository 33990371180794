import AOS from 'aos';

export default class Home {
    constructor() {
        this.init();

    }

    init() {
        AOS.init({disable: 'mobile'})
    }
}