export default class MobileHeader {
    constructor() {
        this.toggleBtn = document.getElementById('js-toggle-mob-menu');
        this.menu = document.getElementById('navigation');
        this.menuActiveClass = 'open';
        this.toggleBtnClass = 'active';
        this.init();
    }


    init() {
        const _this = this;
        this.toggleBtn.addEventListener('click', function (e) {
            e.preventDefault();
            const isOpenMenu = _this.menu.classList.contains(_this.menuActiveClass);
            const isActiveBtn = _this.toggleBtn.classList.contains(_this.toggleBtnClass);

            _this.menu.classList[isOpenMenu ? "remove" : 'add'](_this.menuActiveClass);
            _this.toggleBtn.classList[isActiveBtn ? "remove" : 'add'](_this.toggleBtnClass);
        })
    }

}