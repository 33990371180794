import phoneNumberInForm from "../components/phoneNumberInForm";

export default class CallBackForm {
    constructor(isPopup) {
        this.isPopup = isPopup;
        this.formWrap = document.querySelector('.register-form-wrap');
        this.submitBtn = this.formWrap.querySelector('.js-submit-btn');
        this.successMsg = this.formWrap.querySelector('.js-thanks-text');
        this.errorMsg = this.formWrap.querySelector('.js-error-text');
        this.wayCallback = this.formWrap.querySelector('[name="callback_way"]');
        this.wayCallBackOptionFields = this.formWrap.querySelectorAll('.js-waycallback-option');

        this.emailInput = this.formWrap.querySelector('[name="email"]');
        this.phoneInput = this.formWrap.querySelectorAll('.js-phone-number');

        const phoneNumberForm = new phoneNumberInForm(this.formWrap);
        this.phonesScope = phoneNumberForm.phonesScope;
        this.errorInputClassName = 'error';

        this.init();
    }

    toggleModal() {
        const openClassModal = 'open-modal';
        const _this = this;
        this.openBtn.on('click', function (e) {
            _this.modal.addClass(openClassModal);
        })
        this.btnModalClose.on('click', function (e) {
            _this.modal.removeClass(openClassModal);
        })
    }

    prepareDataForm(form) {
        const formData = new FormData(form);
        const serializedData = {};

        for (const [name, value] of formData) {
            serializedData[encodeURIComponent(name)] = value;
        }

        const choiceCallBackWay = serializedData['callback_way'];
        console.log(choiceCallBackWay , 'choiceCallBackWay!!!!!');

        if (choiceCallBackWay === 'telegram' && serializedData['telegram_phone_number'].length !== 0) {
            const phoneField =  document.getElementById('telegram_phone_number_form');
            phoneField.classList.remove(this.errorInputClassName);
            // serializedData['telegram_phone_number'] = this.phonesScope['telegram_phone_number_form'].getNumber(intlTelInputUtils.numberFormat.E164);
            serializedData['telegram_phone_number'] = phoneField.value.trim();
        } else if (["whats_up", 'phone_number'].includes(choiceCallBackWay)) {
            const phoneField = document.getElementById(`${choiceCallBackWay}_form`);
            console.log(phoneField);
            phoneField.classList.remove(this.errorInputClassName);
            // serializedData[choiceCallBackWay] = this.phonesScope[choiceCallBackWay + '_form'].getNumber(intlTelInputUtils.numberFormat.E164);
            serializedData[choiceCallBackWay] = phoneField.value.trim();
            // this.phonesScope[choiceCallBackWay + '_form'].getNumber(intlTelInputUtils.numberFormat.E164);
        }
        let contact = serializedData[choiceCallBackWay];
        if (choiceCallBackWay === 'telegram') {
            contact = `${serializedData['telegram_phone_number'] || ''} / ${serializedData['telegram_nickname'] || ''}`;
        }
        serializedData['contact'] = contact;
        return serializedData;
    }

    isValidateForm() {
        const _this = this;

        this.phoneInput.forEach(function (elem) {
            if (elem.value.length === 0 && elem.classList.contains(_this.errorInputClassName)) {
                elem.classList.remove(_this.errorInputClassName);
            }
        })

        this.emailInput?.classList.remove(_this.errorInputClassName);
        this.wayCallback.classList.remove(_this.errorInputClassName);

        ['telegram_nickname', 'inst'].forEach(function (id) {
            document.getElementById(id).classList.remove(_this.errorInputClassName);
        })


        const wayCallbackChoice = this.wayCallback.value;
        if (wayCallbackChoice.length === 0) {
            this.wayCallback.classList.add(_this.errorInputClassName);
            return false;
        }

        if (wayCallbackChoice === 'inst') {
            const field = document.querySelector('[name="inst"]');
            const value = field.value;
            if (value.length === 0) {
                field.classList.add(_this.errorInputClassName);
                return false;
            }
        }
        console.log(wayCallbackChoice , 'wayCallbackChoice');
        if (wayCallbackChoice === 'telegram') {
            const fieldIds = ['telegram_phone_number_form', 'telegram_nickname'];
            let fieldsTelegramValue = fieldIds.filter(function (id) {
                return _this.formWrap.querySelector(`#${id}`).value.length !== 0;
            });
            console.log(fieldsTelegramValue , 'fieldsTelegramValue');
            if (fieldsTelegramValue.length === 0) {
                fieldIds.forEach(function (field) {
                    _this.formWrap.querySelector(`#${field}`).classList.add(_this.errorInputClassName);
                })
                return false;
            } else {
                 fieldIds.forEach(function (field) {
                    _this.formWrap.querySelector(`#${field}`).classList.remove(_this.errorInputClassName);
                })
                //_this.formWrap.querySelector(`#${field}`).classList.add(_this.errorInputClassName);
            }
        }

        if (['whats_up', 'phone_number'].includes(wayCallbackChoice)) {
            const field = _this.formWrap.querySelector(`#${wayCallbackChoice}_form`);
            if (field.value.length === 0) {
                field.classList.add(_this.errorInputClassName);
                return false;
            } else {
                field.classList.remove(_this.errorInputClassName);
            }
        }

        if (this.wayCallback.value === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailValue = this.emailInput?.value;
            const isValidEmail = emailRegex.test(emailValue);

            if (!isValidEmail) {
                this.emailInput?.classList.add(_this.errorInputClassName);
                return false;
            }
        }
        const isInvalidNumber = Array.from(this.phoneInput).filter(function (phone) {
            return phone.classList.contains(_this.errorInputClassName);
        })
        console.log(isInvalidNumber , 'isInvalidNumber');
        if (isInvalidNumber.length !== 0) {
            return false;
        }
        return true;
    }


    sendForm() {
        const _this = this;
        this.submitBtn.addEventListener('click', function (e) {
            e.preventDefault();

            if (!_this.isValidateForm()) {
                return false;
            }
            fbq('track', 'Purchase', {currency: "USD", value: 30.00});

            const form = this.closest('form');

            const payload = _this.prepareDataForm(form);
            const csrfToken = payload['csrfmiddlewaretoken'];
            const url = this.getAttribute('data-url');
            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify(payload),
                'headers': {
                    'X-CSRFToken': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    if (data.status) {
                        _this.successMsg.classList.add('show');
                        if (_this.isPopup) {
                        } else {
                            form.classList.add('hide');
                            form.reset();
                        }
                    }
                })
        })
    }

    wayCallBackChoice() {
        const _this = this;
        this.wayCallback.addEventListener('change', function (e) {
            const value = this.value;
            _this.wayCallBackOptionFields.forEach(function (elem, index) {
                elem.classList.add('hidden');
            })

            _this.formWrap.querySelectorAll(`.js-waycallback-option[data-choice="${value}"]`).forEach(function (elem) {
                elem.classList.remove('hidden');
            })

            _this.formWrap.querySelectorAll(`.${_this.errorInputClassName}`).forEach(function (number) {
                number.classList.remove(_this.errorInputClassName);
            })
        })

    }

    init() {
        if (this.isPopup) {
            this.toggleModal();
        }
        this.wayCallBackChoice();
        this.sendForm();
    }
}