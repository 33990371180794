/* Pages */

import Home from "./pages/Home";
import CourseDetail from "./pages/courseDetail";
import Blog from "./pages/blog";
import ReviewsPage from "./pages/reviews";
import Faq from "./pages/faq";
import ArticlePage from "./pages/article";
import Webinar from "./pages/webinars";
import MaraphonPage from "./pages/maraphon";

/* Components */
import MobileHeader from "./components/header";
import AutoWriteText from "./components/autoWriteText";
import Video from "./components/video";
import CallBackForm from "./components/callBackForm";
import Reviews from "./components/reviews";
import GoToScroll from "./components/goToScroll";
import LazyLoadImg from "./components/lazyLoadImg";
import Timer from "./components/timer";


class Root {
    constructor() {
        this.Home = Home;
        this.AutoWriteText = AutoWriteText;
        this.Video = Video;
        this.CallBackForm = CallBackForm;
        this.Reviews = Reviews;
        this.CourseDetail = CourseDetail;
        this.GoToScroll = GoToScroll;
        this.Blog = Blog;
        this.ReviewsPage = ReviewsPage;
        this.MobileHeader = MobileHeader;
        this.Faq = Faq;
        this.ArticlePage = ArticlePage;
        this.Webinar = Webinar;
        this.MaraphonPage = MaraphonPage;
    }

}

var root = new Root();
window.Root = root;


new LazyLoadImg().init();
