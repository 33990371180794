export default class GoToScroll {
    constructor() {
        this.btns = document.querySelectorAll('.js-go-to');
        this.init();
    }
    
    init() {
        this.btns.forEach(function (elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                const toBlockId = this.getAttribute('data-to');
                document.querySelector(`#${toBlockId}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            })
        })
    }
}