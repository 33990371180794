export default class AutoWriteText {
    constructor() {
        this.writeTextElems = [...document.querySelectorAll('[data-autowrite-text]')];
        this.counterElems = [...document.querySelectorAll('[data-counter]')]
        this.defaultSpeed = 100;
        this.init();
    }

    write(elem) {
        const text = elem.getAttribute('data-autowrite-text');
        const speed = elem.getAttribute('data-autowrite-speed');

        let index = 0;
        const _this = this;
        elem.style.height = elem.offsetHeight + "px";
        const intervalId = setInterval(function () {
            elem.innerHTML = text.slice(0, index);
            index++;
            if (index > text.length) {
                index = 0;
                clearInterval(intervalId);
            }
            if (index == 1) {
                elem.classList.remove('inactive');
            }
        }, speed || _this.defaultSpeed);
    }

    counter(elem) {
        const number = parseInt(elem.getAttribute('data-counter'));
        const _this = this;
        let upto = 0;
        const intervalId = setInterval(function () {
            elem.innerHTML = ++upto;
            if (upto === number) {
                clearInterval(intervalId);
            }
        }, _this.defaultSpeed);
    }

    init() {
        const _this = this;
        this.writeTextElems.forEach(function (elem, _) {
            _this.write(elem);
        })

        this.counterElems.forEach(function (elem, _) {
            _this.counter(elem)
        })
    }
}