import AOS from 'aos';
import phoneNumberInForm from "../components/phoneNumberInForm";


export default class CourseDetail {
    constructor() {
        this.modal = document.querySelector('.js-modal');

        this.tabs = document.querySelectorAll('.js-tab-btn');
        this.tabsContent = document.querySelectorAll('.js-tab-content');
        this.priceField = document.querySelector('.js-price');
        this.closeModalBtn = document.querySelector('.js-close');
        this.overlay = document.querySelector('.background-overlay');

        this.openModalBtns = document.querySelectorAll('.js-modal-register-open');

        this.wayCallback = this.modal.querySelector('[name="callback_way"]');
        this.wayCallBackOptionFields = this.modal.querySelectorAll('.js-waycallback-option');

        this.emailInput = this.modal.querySelector('[name="email"]');
        this.phoneInput = this.modal.querySelectorAll('.js-phone-number');


        this.jsCheckPromoBtns = document.querySelectorAll('.js-check-promo-btn');
        this.promoResultOutputModal = document.getElementById('promo_result_modal');
        this.promoResultOutput = document.getElementById('promo_result');
        this.form = document.getElementById('registers-now');
        this.promoInfoField = document.querySelector('.js-promo-info');
        this.sendRegisterFormBtn = document.querySelector('.js-send-register-course');

        this.successMsg = this.modal.querySelector('.js-thanks-text');
        this.errorMsg = this.modal.querySelector('.js-error-text');

        this.promoConfig = {
            oldPrice: this.priceField.innerHTML
        };
        this.errorInputClassName = 'error';

        const phoneNumberForm = new phoneNumberInForm(this.form);
        this.phonesScope = phoneNumberForm.phonesScope;
        this.init();

    }



    wayCallBackChoice() {
        const _this = this;
        this.wayCallback.addEventListener('change', function (e) {
            const value = this.value;
            _this.wayCallBackOptionFields.forEach(function (elem, index) {
                elem.classList.add('hidden');
            })
            _this.modal.querySelectorAll(`.js-waycallback-option[data-choice="${value}"]`).forEach(function (elem) {
                elem.classList.remove('hidden');
            })
        })

    }

    switchTabs() {
        const _this = this
        this.tabs.forEach(function (tab) {
            tab.addEventListener('click', function (e) {

                _this.tabs.forEach(function (elem) {
                    elem.classList.remove('active');
                })
                const targetTabPaneId = this.getAttribute('href');
                this.classList.add('active');
                _this.tabsContent.forEach(function (tabContent) {
                    tabContent.classList.remove('active');
                })
                document.getElementById(targetTabPaneId).classList.add('active');
                _this.activeTabPan = targetTabPaneId;
                _this.checkIsPriceTabActive();
            })
        })
    }

    checkIsPriceTabActive(){
        if (this.activeTabPan === 'price' && Object.keys(this.promoConfig).length !== 1) {
            document.querySelector('.js-promocode-form').remove();
            const msg = `Ви використали Промокод "${this.promoConfig.promoValue}". Ваша знижка - <span class="accent"><b>${this.promoConfig.percentSale}%</b></span>.`;
            this.promoResultOutput.innerHTML = msg;
        }
    }

    toggleModal() {
        const _this = this;
        [_this.closeModalBtn, _this.overlay].forEach(function (closeTriggerElem) {
            closeTriggerElem.addEventListener('click', function (e) {
                _this.overlay.classList.remove('show');
                _this.modal.classList.remove('show');
                _this.checkIsPriceTabActive();
            })
        })

        this.openModalBtns.forEach(function (openBtn) {
            openBtn.addEventListener('click', function () {
                _this.overlay.classList.add('show');
                _this.modal.classList.add('show');

                _this.setPromoValueToForm();
                if (Object.keys(_this.promoConfig).length !== 1) {
                    const {newPrice, promoValue, percentSale} = _this.promoConfig;
                    let msg = `Ви використали Промокод "${promoValue}". Ваша знижка - <span class="accent"><b>${percentSale}%</b></span>.`;
                    _this.modal.querySelector('.js-modal-price').innerHTML = `Ціна: <span class="accent">${newPrice}€</span>`;
                    _this.modal.querySelector('#promo_result_modal').innerHTML = msg;
                    _this.modal.querySelector('.js-modal-promo').classList.add('hidden');
                    return;
                }
            })
        })
    }

    reCalcPrice(percentSale, inPopup) {
        const oldPrice = parseFloat(this.promoConfig.oldPrice);
        const newPrice = Math.round((1 - percentSale / 100) * oldPrice, 2);
        const msg = `Ви використали Промокод "${this.promoConfig.promoValue}". Ваша знижка - <span class="accent"><b>${percentSale}%</b></span>.`;
        const targetMsgElem = inPopup ? this.promoResultOutputModal : this.promoResultOutput;
        if (inPopup) {
            this.form.querySelector('.js-modal-promo').classList.add('hidden');
        }
        targetMsgElem.innerHTML = msg;
        targetMsgElem.style.display = 'block';
        this.priceField.innerText = newPrice;
        this.form.querySelector('.js-modal-price').innerHTML = `Ціна: <span class="accent">${newPrice}€</span>`;
        return newPrice;
    }

    checkPromo() {
        const _this = this;
        this.jsCheckPromoBtns.forEach(function (elem) {
            elem.addEventListener('click', function (e) {
                e.preventDefault();
                //const promoNotFoundElem = document.querySelector('.js-promo-not-found');
                document.querySelector('.js-promo-not-found')?.remove();
                const url = this.getAttribute('data-href');
                const inPopup = this.getAttribute('data-target-popup') === 'true';
                const courseId = this.getAttribute('data-course_id');
                let promoValue = null;
                if (!inPopup) {
                    promoValue = this.closest('.js-tab-content').querySelector('.js-promo-code-input').value;
                } else {
                    promoValue = this.closest('.js-modal-promo').querySelector('.js-promo-code-input-modal').value;
                }
                fetch(url + "?" + new URLSearchParams(
                    {
                        'promo_value': promoValue,
                        'course_id': courseId
                    }
                ), {}).then((response) => response.json())
                    .then(data => {
                        if (data.status) {
                            const percentSale = data.percent_sale;
                             _this.promoConfig.promoValue = promoValue;
                            const newPrice = _this.reCalcPrice(percentSale, inPopup);
                            _this.promoConfig.newPrice = newPrice;
                            _this.promoConfig.percentSale = percentSale;
                            _this.promoConfig.promo_id = data.promo_id;
                            _this.setPromoValueToForm();
                            if (!inPopup) {
                                _this.openModalBtns[0].click();
                            }
                        } else {
                            const modalPromoSelector = inPopup ? '.js-modal-price' : '.js-promocode-form';
                            const modalPromo = document.querySelector(modalPromoSelector);
                            const notFoundPromoCodeElem = document.createElement('span');
                            notFoundPromoCodeElem.classList.add('js-promo-not-found');
                            notFoundPromoCodeElem.classList.add('promo-not-found');
                            notFoundPromoCodeElem.innerHTML = `Промокод "${promoValue}" не знайдено.`;
                            modalPromo.append(notFoundPromoCodeElem)
                        }
                    })
            })
        })
    }

    setPromoValueToForm() {
        this.promoInfoField.value = JSON.stringify(this.promoConfig);
    }

    isValidateForm() {
        const _this = this;
        this.phoneInput.forEach(function (elem) {
            if (elem.value.length === 0 && elem.classList.contains(_this.errorInputClassName)) {
                elem.classList.remove(_this.errorInputClassName);
            }
        })

        this.emailInput?.classList.remove(_this.errorInputClassName);
        this.wayCallback.classList.remove(_this.errorInputClassName);

        ['telegram_nickname', 'inst'].forEach(function (id) {
            _this.form.querySelector(`#${id}`).classList.remove(_this.errorInputClassName);
        })


        const wayCallbackChoice = this.wayCallback.value;

        if (wayCallbackChoice.length === 0) {
            this.wayCallback.classList.add(_this.errorInputClassName);
            return false;
        }

        if (wayCallbackChoice === 'inst') {
            const field = _this.form.querySelector('[name="inst"]');
            const value = field.value;
            if (value.length === 0) {
                field.classList.add(_this.errorInputClassName);
                return false;
            }
        }
        if (wayCallbackChoice === 'telegram') {
            const fieldIds = ['telegram_phone_number', 'telegram_nickname'];
            let fieldsTelegramValue = fieldIds.filter(function (id) {
                return _this.form.querySelector(`#${id}`).value.length !== 0;
            });
            if (fieldsTelegramValue.length === 0) {
                fieldIds.forEach(function (field) {
                    _this.form.querySelector(`#${field}`).classList.add(_this.errorInputClassName);
                })
                return false;
            } else {
                 fieldIds.forEach(function (field) {
                    _this.form.querySelector(`#${field}`).classList.remove(_this.errorInputClassName);
                })
            }
        }

        if (['whats_up', 'phone_number'].includes(wayCallbackChoice)) {
            const field = _this.form.querySelector(`#${this.wayCallback.value}`);
            if (field.value.length === 0) {
                field.classList.add(_this.errorInputClassName);
                return false;
            } else {
                 field.classList.remove(_this.errorInputClassName);
            }
        }

        if (this.wayCallback.value === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const emailValue = this.emailInput?.value;
            const isValidEmail = emailRegex.test(emailValue);

            if (!isValidEmail) {
                this.emailInput?.classList.add(_this.errorInputClassName);
                return false;
            }
        }
        const isInvalidNumber = Array.from(this.phoneInput).filter(function (phone) {
            return phone.classList.contains(_this.errorInputClassName);
        })

        if (isInvalidNumber.length !== 0) {
            return false;
        }
        return true;
    }

    prepareDataForm(form) {
        const formData = new FormData(form);
        const serializedData = {};

        for (const [name, value] of formData) {
            serializedData[encodeURIComponent(name)] = value;
        }

        const choiceCallBackWay = serializedData['callback_way'];

        if (choiceCallBackWay === 'telegram' && serializedData['telegram_phone_number'].length !== 0) {
            const field = document.getElementById('telegram_phone_number');
            // serializedData['telegram_phone_number'] = this.phonesScope['telegram_phone_number'].getNumber(intlTelInputUtils.numberFormat.E164);
            serializedData['telegram_phone_number'] = field.value.trim();
        } else if (["whats_up", 'phone_number'].includes(choiceCallBackWay)) {
            const field = document.getElementById(choiceCallBackWay);
            serializedData[choiceCallBackWay] = field.value.trim();
        }
        let contact = serializedData[choiceCallBackWay];
        if (choiceCallBackWay === 'telegram') {
            contact = `${serializedData['telegram_phone_number'] || ''} / ${serializedData['telegram_nickname'] || ''}`;
        }
        serializedData['contact'] = contact;
        return serializedData;
    }

    submitForm() {
        const _this = this;
        this.sendRegisterFormBtn.addEventListener('click', function (e) {
            e.preventDefault();
            if (!_this.isValidateForm()) {
                return false;
            }
            const url = _this.form.getAttribute('action');
            const payload = _this.prepareDataForm(_this.form);
            const csrfToken = payload['csrfmiddlewaretoken'];
            fetch(url, {
                'method': 'POST',
                'body': JSON.stringify(payload),
                'headers': {
                    'X-CSRFToken': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then(data => {
                    if (data.status) {
                        _this.form.classList.add('hidden');
                        // $('.js-modal-promo').hide();
                        // _this.successMsg.addClass('position-relative');
                        _this.successMsg.classList.add('show');
                        setTimeout(function () {
                            _this.closeModalBtn.click();
                        }, 3000)
                    } else {
                        // _this.errorMsg.addClass('show');
                    }
                })
        })
    }

    checkPromoLoadingPage(){
        const urlParams = new URLSearchParams(window.location.search);
        const promoValue =  urlParams.get('promo_code');
        if (!promoValue) {
            return;
        }
        document.querySelector('.js-tab-btn[href="price"]').click();
        document.querySelector('.js-promo-code-input').value = promoValue;
        document.querySelector('.js-check-promo-btn').click();

    }

    init() {
        AOS.init({disable: 'mobile'});
       // new phoneNumberInForm().init();
        this.switchTabs();
        this.toggleModal();
        //this.addMaskToPhone();
        this.wayCallBackChoice();
        this.checkPromo();
        this.submitForm();
        this.checkPromoLoadingPage();
    }
}