export default class Timer {
    constructor(seconds, selectorWrapTimerId, storageTimerKey) {
        this.wrap = document.getElementById(selectorWrapTimerId);
        this.containers = [...this.wrap.querySelectorAll('.container-sub')];
        this.hours = this.wrap.querySelector('.hours');
        this.minutes = this.wrap.querySelector('.minutes');
        this.seconds = this.wrap.querySelector('.seconds');

        this.storageTimerKey = storageTimerKey;
        this.secondsCountDown = parseInt(seconds);
        this.init();
    }

    countDown(component) {
        const times = [{}, {}, {}];
        const _this = component;
        let total = component.secondsCountDown;

        const secs = component.secondsCountDown % 60;
        let s1 = Math.floor(total % 60)
        times[2].front = secs;
        times[2].back = (s1 === 0 ? 59 : s1 - 1);

        total /= 60
        let m1 = Math.floor(total % 60)
        const minutes = Math.floor((component.secondsCountDown % 3600) / 60); // 1 minute = 60 seconds
        times[1].front = minutes;
        times[1].back = (m1 === 0 ? 59 : m1 - 1);

        total /= 60
        let h1 = Math.floor(total % 24)
        const hours = Math.floor(component.secondsCountDown / 3600)
        times[0].front = hours;
        times[0].back = (h1 === 0 ? 23 : h1 - 1);


        let i = 0;
        _this.containers.forEach(container => {
            const clock = container.querySelector('.clock')

            const front = container.querySelector('.front')
            const back = container.querySelector('.back')

            const frontTime = (times[i].front < 10 ? '0' + times[i].front : times[i].front);
            const backTime = (times[i].back < 10 ? '0' + times[i].back : times[i].back);

            clock.dataset.before = frontTime;
            clock.dataset.after = backTime;
            front.textContent = frontTime;
            back.textContent = backTime;

            container.addEventListener('animationend', () => {
                container.classList.remove('flip')
                front.textContent = back.textContent
                clock.dataset.before = clock.dataset.after
            }, {once: true})

            i++;
        })

        _this.seconds.parentElement.classList.add('flip')

        if (_this.seconds.dataset.before === '00') {
            _this.minutes.parentElement.classList.add('flip')
        }
        if (_this.seconds.dataset.before === '00'
            && _this.minutes.dataset.before === '00') {
            _this.hours.parentElement.classList.add('flip')
        }

        if (_this.seconds.dataset.before === '01'
            && _this.minutes.dataset.before === '00'
            && _this.hours.dataset.before === '00') {
        }
        if (component.secondsCountDown <= 0){
            component.secondsCountDown = 24 * 60 * 60;
        } else {
            --component.secondsCountDown;
        }
    }


    init() {
        const _this = this;
        this.countDown(_this);
        setInterval(function () {
            _this.countDown(_this);
        }, 1000)
    }

}

window.Timer = Timer;