import phoneNumberInForm from "../components/phoneNumberInForm";


export default class Webinar {
    constructor() {
        this.formWrap = document.querySelector('.form-webinar-wrap');
        this.submitBtn = document.querySelector('.js-send-register-webinar');
        this.fields = {
            'name': document.querySelector('[name="first_name"]'),
            'callback_way': document.querySelector('[name="callback_way"]'),
            'phone_number': document.querySelector('[name="phone_number"]'),
        };

        this.errorInputClassName = 'error';
        this.errorField = document.querySelector('.js-error-fields');
        this.successField = document.querySelector('.js-success-text');
        this.csrfToken = document.querySelector('[name="csrfmiddlewaretoken"]').value;
        this.webinarId = +document.querySelector('[name="actual_webinar_id"]').value;
        this.tgJoinBtn = document.getElementById('join_tg');
        this.url = this.submitBtn.dataset['url'];
        const phoneNumberForm = new phoneNumberInForm(this.formWrap);
        this.phonesScope = phoneNumberForm.phonesScope;

        this.init();
    }

    validate() {
        const _this = this;
        let errors = 0;
        let errorMsg = '';
        this.errorField.innerHTML = '';
        for (const [fieldName, field] of Object.entries(this.fields)) {
            const fieldValue = field.value.trim();
            field.classList.remove(_this.errorInputClassName);
            if (!fieldValue) {
                field.classList.add(_this.errorInputClassName);
                errors++;
                errorMsg = "Ці поля обов'язкові для заповнення.";
            }
            // else if (fieldName === 'phone_number' && !_this.phonesScope["webinar_phone_number"].isValidNumber()) {
            //     errors++;
            //     errorMsg = 'Введіть валідний номер телефону';
            //     field.classList.add(_this.errorInputClassName);
            // }
        }
        return [errors, errorMsg];
    }

    sendRegisterForm() {
        const _this = this;
        this.submitBtn.addEventListener('click', function (e) {
            const [errors, errorMsg] = _this.validate();
            if (errors) {
                _this.errorField.innerHTML += errorMsg;
            } else {
                // const numberPhone = _this.phonesScope["webinar_phone_number"].getNumber(intlTelInputUtils.numberFormat.E164);
                _this.submitForm({
                    'name': _this.fields.name.value.trim(),
                    'callback_way': _this.fields.callback_way.value.trim(),
                    // 'phone_number': numberPhone,
                    'phone_number': _this.fields.phone_number.value.trim(),
                    'webinar_id': _this.webinarId
                });
            }
        })
    }

    submitForm(payload) {
        const _this = this;
        fetch(this.url, {
            'method': 'POST',
            'body': JSON.stringify(payload),
            'headers': {
                'X-CSRFToken': _this.csrfToken,
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then(data => {
                if (data.status) {
                    _this.formWrap.classList.add('hide');
                    _this.successField.innerHTML = data.msg;
                    _this.successField.style.display = 'block';
                    _this.tgJoinBtn.classList.remove('hidden');
                } else {
                    _this.errorField.innerHTML = data.msg;
                    console.log(data.msg);
                }
            })
    }

    init() {
        this.sendRegisterForm();
    }

}